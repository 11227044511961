import './index.css';
import Navigation from './components/Nav.js';
import Home from './components/Sections/Home';
import About from './components/Sections/About';
import AboutJeff from './components/Sections/AboutJeff';
// import Video from './components/Sections/Video';
import Audio2 from './components/Sections/Audiov2'
// import Audio from './components/Sections/Audio'
import Contact from './components/Sections/Contact';
import { BrowserRouter , Routes, Route} from 'react-router-dom';
//import Booking from './components/Sections/Booking';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Footer from './components/Footer';
import AudioCorp from './components/Sections/AudioCorp'
import AudioCountry from './components/Sections/AudioCountry'
import AudioRock from './components/Sections/AudioRock'
import AudioJam from './components/Sections/AudioJam'
import AudioMovie from './components/Sections/AudioMovie'
import AudioJazz from './components/Sections/AudioJazz'
import AudioUnplugged from './components/Sections/AudioUnplugged'
import AudioBlues from './components/Sections/AudioBlues'
import AudioDocumentary from './components/Sections/AudioDocumentary'
import GearList from './components/Sections/GearList';
import Studio from './components/Sections/StudioServ'

//import { Navbar } from 'react-bootstrap';

//const [currentPage, handlePageChange] = useState('Home');

function App() {
  return (
    <BrowserRouter>
      <Navigation />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/About' exact element={<About />} />
          <Route path='/AboutJeff' exact element={<AboutJeff />} />
          {/* <Route path='/GearList' exact element={<GearList />} /> */}
          {/* <Route path='/Video' exact element={<Video />} /> */}
          <Route path='/Audio' exact element={<Audio2 />} />
          <Route path='/Studio' exact element={<Studio />} />
          {/* <Route path='/Booking' exact element={<Booking />} /> */}
          <Route path='/Contact' exact element={<Contact />} />
        </Routes>
        <Routes>
          <Route element={<Audio2 />} />
          <Route path="/AudioDocumentary" element={<AudioDocumentary />} />
          <Route path="/AudioCorp" element={<AudioCorp />} />
          <Route path="/AudioCountry" element={<AudioCountry />} />
          <Route path="/AudioRock" element={<AudioRock />} />
          <Route path="/AudioJam" element={<AudioJam />} />
          <Route path="/AudioMovie" element={<AudioMovie />} />
          <Route path="/AudioJazz" element={<AudioJazz />} />
          <Route path="/Audiounplugged" element={<AudioUnplugged />} />
          <Route path="/AudioBlues" element={<AudioBlues />} />
        </Routes>
        <Routes>
            <Route element={<Studio/>} />
            <Route path="/GearList" element={<GearList/>}/>
        </Routes>
        <Footer></Footer>
    </BrowserRouter>
  )

}
  export default App;

