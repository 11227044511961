import React from "react";
//import GearList from "./GearList";
import { Button, Col, Container, Row} from "react-bootstrap";
import drums from '../../assets/DrumsBW.png'
import mic from '../../assets/micBG.jpg'
import amps from '../../assets/PreampsMain.png'
import snare from '../../assets/Rosewood Snare.png'
import studio from '../../assets/StudioMain.png'
//import drumClose from '../../assets/drumClose.png'
import session from '../../assets/session.png'
import mic2 from '../../assets/SHURE SM7MIC.JPG'
import mic3 from '../../assets/4400 MIC.jpeg'
import guitarcab from '../../assets/GuitarCabs.png'
import kick from '../../assets/kick.png'
import jazzdrum from '../../assets/ArtisanJazzdrums.png'
//import board from '../../assets/MillsMixBrdVin.png'
import Neum from '../../assets/Neumannmic.JPG'
import leslie from '../../assets/LeslieCab.png'

export default function StudioServices() {
    return (
        <>
            <Container className="studio py-4">
                <div className="serv-header pb-3">
                    <h2 className="bg text-center" id="studio-svc">Studio Services</h2>
                    
                    <p className="text-white text-center">Our studio is equipped to meet the needs of most projects</p>
                    <p className="text-white text-center">Expert mixing of multi-track recordings for industry-quality release: Single, Demo, Album, Movie Soundtrack</p>
                    <p className="text-white text-center"> Custom composing and editing for Movies/TV. Includes formulating pieces to exact time codes and desired atmosphere for each scene.</p>
                </div>
                <div className="d-grid gap-2">
                <Button href="/GearList" className="gearBtn" size="large" variant="light"> Full Equipment List </Button>
                </div>

            {/*Need to add id and className so when clicked it brings you here and for styling! */}
            {/* <h2 id="studioHeader" className="bg text-center">Studio Services</h2>
                <p className="lead text-center"> Our studio is equipped to meet the needs of most projects.</p> 
                <p className="text-white text-center">Expert mixing of multi-track recordings for industry-quality release: Single, Demo, Album, Movie Soundtrack</p>
                <p className="text-white text-center"> Custom composing and editing for Movies/TV. Includes formulating pieces to exact time codes and desired atmosphere for each scene.</p> */}
                <Row id="studio-collage">
                    <Col id="collage">
                        <img src={drums} alt="drums"/>
                        <img src={mic3} alt="microphone"/>
                        <img src={studio} alt="studio shot"/>
                    </Col>
                    <Col id="collage">
                        <img src={mic} alt="microphone"/>
                        <img src={jazzdrum} alt="jazz drums"/>
                        <img src={Neum} alt="Neumann microphone"/>
                    </Col>
                    <Col id="collage">
                        <img src={amps} alt="amps"/>
                        <img src={session} alt="studio view"/>
                        <img src={guitarcab} alt="guitar cabinet"/>
                    </Col>
                    <Col id="collage">
                        <img src={snare} alt="rosewood snare drum"/>
                        <img src={mic2} alt="microphone"/>
                        <img src={leslie} alt="kickdrum"/>
                    </Col>

                </Row> 
            </Container>
        </>
    )
}