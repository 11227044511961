import React from 'react';
import { Container, Button, Row, Col } from "react-bootstrap";
import mic from '../../assets/mic.jpg';
import Card from "react-bootstrap/Card";
import CardGroup from 'react-bootstrap/CardGroup';
import logo from '../../assets/360v5.png';

function Audio() {
    return (
        <>
            <Container fluid>
                <div className='Audio-header'>
                    <img id="micImgAudio" src={mic} alt="Microphone" />
                    <div className="overlay justify-items-center" fluid>
                        {/* <p id="ImgHeading" className='col-md-7 fluid'>From full-length albums to movie soundtracks and voice over work, threeSixty Sessions can create the audio you need for your project or business.</p> */}
                        <p id="ImgHeading">Samples were recorded & mixed exclusively in-house and can be found on mainstream music platforms.</p>
                    </div>
                </div>
                {/*start of the cards */}
                <div className="audioCardContainer col-md-12 col-sm fluid">
                    <h2 id="sample-title">Audio Samples</h2>
                <Row>
                    
                    <CardGroup id="cardGroup">
                        <Col>

                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'><em>Jimmy Carter</em> Soundtrack</Card.Title>
                                <Button href="/AudioDocumentary" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>

                        <Card id='audioCard' className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Country</Card.Title>
                                <Button id="audio-listen" href="/AudioCountry" variant="warning" >listen</Button>
                            </Card.Body>
                        </Card>

                        <Card id='audioCard' className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Commercial</Card.Title>
                                <Button href="/AudioCorp" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>
                        
                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Rock</Card.Title>
                                <Button href="/AudioRock" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>

                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Pop Rock</Card.Title>
                                <Button href="/AudioJazz" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col>
                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Blues</Card.Title>
                                <Button href="/AudioBlues" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>

                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Solos</Card.Title>
                                <Button href="/AudioUnplugged" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>

                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>Soundtrack</Card.Title>
                                <Button href="/AudioMovie" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>

                        <Card id="audioCard" className='text-center text-white bg-black' style={{ width: '30rem' }}>
                            <Card.Img id='audio-img' variant="top" src={logo} alt="logo" />
                            <Card.Body>
                                <Card.Title className='audio-title'>JamBands</Card.Title>
                                <Button href="/AudioJam" variant="warning">listen</Button>
                            </Card.Body>
                        </Card>
                        </Col>
                    </CardGroup>
                    </Row>
                </div>
            </Container>
        </>

    )
}

export default Audio