import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Card from "react-bootstrap/Card";
import MillsMainMix from '../../assets/MillsMixBrdv2.png'
import millsMix from '../../assets/MillsMixBrdVin.png';
import Studio from '../../assets/StudioMaincopy.png'
//import drums from '../../assets/drumKit.png';
//import musicSet from '../../assets/studioSetREV.png';
import logo from '../../assets/360v5.png';
import record from '../../assets/oldRec.jpg';
import mike from '../../assets/mic.jpg';
import amp from '../../assets/PreampsMain.png'
//import master from '../../assets/MillsMixBrd.jpg'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button } from "react-bootstrap";

export default function Home() {
    return (
        <>
            <div className="container-fluid hero-slide">
                <div className="carousel-fade">
                    {/* <div className="caption">
                        <img className='logo-carousel' src={logo} alt="logo"/>
                    </div> */}
                </div>
                <Carousel fade>
                    <Carousel.Item className='carousel-item'>
                        <img
                            className="d-block w-100"
                            src={millsMix}
                            alt="mixing board"
                        />
                        <Carousel.Caption>
                            <h3 id="carousel-text">Welcome to three sixty sessions!</h3>
                            <p>Innovative studios solutions</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={amp}
                            alt="Pre Amps"
                        />

                        <Carousel.Caption>
                            <h3>World-Class Professional Equipment</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Studio}
                            alt="Main Studio"
                        />

                        <Carousel.Caption>
                            <h3>Large Tracking Room</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div>
                <div className="welcome bg-dark text-white">
                    {/* <h1>Welcome to three sixty sessions</h1> */}
                    <div className="caption">
                        <img className='logo-carousel' src={logo} alt="logo"/>
                    </div>
                </div> 
            </div>
            <div className="container px-4 py-5" id="custom-cards">
                <h2 className="text-center pb-2 border-bottom" >Welcome to three sixty sessions</h2>

                <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">

                <Card id="home-card" className="card card-cover h-75 overflow-hidden text-center text-white rounded-5 shadow-lg bg-transparent text-white">
                    <Card.Img id="vinyl-img" src={record} alt="vinyl album" />
                    <div className="centered" id="body-text">TRACKING</div>
                    <Card.ImgOverlay className="overlay-home">
                        <Card.Text className="home-text">
                        Isolated control room with Presonus24Ai known for its ultra-quiet and warm preamps
                        </Card.Text>
                        <Card.Text></Card.Text>
                    </Card.ImgOverlay>
                </Card>

                {/* <div class="col">
                    <div class="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" >
                        <img src={mike} alt='microphone'/>
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Much longer title that wraps to multiple lines</h2>
                        
                    </div>
                    </div>
                </div> */}
                <Card id="home-card" className="card card-cover overflow-hidden text-center text-white rounded-5 shadow-lg bg-transparent text-white">
                    <Card.Img id="home-img" src={mike} alt="microphone" />
                    <div className="centered" id="body-text">SOUND MIXING</div>
                    <Card.ImgOverlay className="overlay-home">
                        <Card.Text className="home-text">
                        We have world class studio musicians to bring your music to life
                        </Card.Text>
                        <Card.Text></Card.Text>
                    </Card.ImgOverlay>
                </Card>

                <Card id="home-card" className="card card-cover h-75 overflow-hidden text-center text-white rounded-5 shadow-lg bg-transparent text-white">
                    <Card.Img id="home-img" className="mixboard" src={MillsMainMix} alt="mixing board" />
                    <div className="centered">MASTERING</div>
                    <Card.ImgOverlay className="overlay-home">
                        <Card.Text className="home-text">
                        
                        Capture your unique sound in the highest quality digital audio for your next project
                        </Card.Text>
                        <Card.Text></Card.Text>
                    </Card.ImgOverlay>
                </Card>
                </div>
                <div className="d-grid gap-1 col-6 mx-auto" id="work-homeBtn">
                    <Button href="/Audio" className="work-homeBtn text-white" variant="transparent" size="lg">
                        Hear Our Work
                    </Button>
                </div>
            </div>
        </>
    )
}