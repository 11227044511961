import React from 'react'
import { Container } from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {FaInstagram, FaLinkedinIn, FaRegEnvelope} from 'react-icons/fa'
import Feature from '../assets/AsSeenInpng.png'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <Container fluid id='footer'>
                <hr></hr>
                <div className='featured-in'>
                    <h6>AS FEATURED IN</h6>
                    <div className='col-12 feat-logos'>
                        <img className='feature-logo' src={Feature} alt="company logos"/>
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-xs-12 footer-main'>
                    <div className='col-xs-4 col-md-4 footer-left'>
                        <ul className='footer-about'>
                            <Link
                            to='/About'
                            className='nav-links'
                            >About Us
                            </Link>

                            <Link
                            to='/Contact'
                            className='nav-links'
                            >Booking
                            </Link>

                            <Link
                            to='/Contact'
                            className='nav-links'
                            >Contact
                            </Link>
                        </ul>
                    </div>
                    <div className='col-xs-4 col-md-4 footer-middle text-white'>
                        <h6>Interested in Booking a Session</h6>
                        <Button href="mailto:jmojom@gmail.com" target="_blank" rel="noreferrer" variant="light">Contact Us</Button>{''}
                    </div>
                    <div className='col-xs-4 col-md-push-4 footer-right col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"'>
                        <div className='footer-social'>
                            <h5>Follow Us</h5>
                            {/* <a href="#"><i className="fa fa-facebook fa-5x"><FaFacebook size='25px'/></i></a> */}
                            <a href="https://www.linkedin.com/in/jeff-mills-84724449/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"><FaLinkedinIn size='25px'/></i></a>
                            <a href="mailto:jmojom@gmail.com" target="_blank" rel="noreferrer"><i className="fa fa-mail"><FaRegEnvelope size='25px'/></i></a>
                            <a href="https://www.instagram.com/360sessionstudios/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"><FaInstagram size='25px'/></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 footer-bottom">
                    <div className="footer-copyright">
                        <p> ©threesixty sessions 2022 - All rights reserved. Site: JJLindsey RBlessing</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}


