import React from 'react';
import { useState } from 'react';
import logo from '../../assets/360v5.png'
import Waveform from '../Sections/waveform'
import PlayList from "../Sections/Playlist";
import { Card, Container, Row } from 'react-bootstrap';

const tracks= [
    {
        id: 1,
        title:'Mortgage Rhino',
        url: '../../../audio/mortgage-rhino.mp3',
        artist: 'JEFF MILLS'
    }
]


function AudioCorp() {

    const [selectedTrack, setSelectedTrack] = useState(tracks[0]);

    return (
        // <>
        //     <div id='audiorock'>
        //         <div id='waveform'>
        //             <img className='player-img' src={logo} alt='logo'/>
        //             <Waveform  
        //                     url={selectedTrack.url}
        //                 />
        //                 <div id='playlist'>
        //                     <h6 id='playlist-title'>PLAYLIST:</h6>
        //                     <PlayList
        //                         tracks={tracks}
        //                         selectedTrack={selectedTrack}
        //                         setSelectedTrack={setSelectedTrack}
        //                     />
        //                 </div>   
        //         </div>
        //     </div>
        // </>
        <>
            <Container id="audio">
                <div id='audiorock'>
                    <Row id="genre-card">
                        <Card id="card-player" style={{ width: '25rem' }}>
                            <Card.Img id="player-img" variant="top" src={logo} />
                            <Card.Body>
                                <div id='waveform'>
                                    <Waveform  
                                            url={selectedTrack.url}
                                        />
                                        <div id='playlist'>
                                            <h6 id='playlist-title'>PLAYLIST:</h6>
                                            <PlayList
                                                tracks={tracks}
                                                selectedTrack={selectedTrack}
                                                setSelectedTrack={setSelectedTrack}
                                            />
                                        </div>   
                                </div>
                            </Card.Body>
                        </Card>
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default AudioCorp
