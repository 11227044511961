import React, {useRef} from 'react'
import { Container, Form } from 'react-bootstrap'
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
//import logo from '../../assets/360v5.png'
import emailjs, { init } from '@emailjs/browser'
init("user_oAv2xu8ZZUhE98rxk1yy8")


function Contact() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_zh83bcd', 'template_ztowtfg', e.target, 'user_oAv2xu8ZZUhE98rxk1yy8')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text)
            });
    };

    return (
        <Container className="contact" fluid>
            <Card className="contact-card mx-auto py-5 text-center text-white">
                <Card.Header id="contact-header">Contact</Card.Header>
                    <Card.Body>
                        <Card.Title>three sixty sessions</Card.Title>
                        <Card.Text>
                            <ListGroup className="list-group-flush" id="contact-list">
                                ADDRESS
                                <ListGroupItem id="contact-list">Duluth, GA</ListGroupItem>
                                <ListGroupItem id="contact-list">PHONE: 504-615-8725</ListGroupItem>
                            </ListGroup>
                        </Card.Text>
                        <Card.Text>For Booking & Information:</Card.Text>
                        <Form className='contact-form' onSubmit={sendEmail}>
                            <h4>Contact Form</h4>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="user_name" placeholder="Name"/>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="user_email" placeholder="name@example.com" />
                            <Form.Label>Message</Form.Label>
                            <Form.Control name="message" as="textarea" rows={4} placeholder="fill in message for information"/>
                            <Button type='submit' value='Send' size="lg" id="contact-send">Send</Button>
                        </Form>
                        {/* <Button href="mailto:jmojom@gmail.com" target="_blank" rel="noreferrer" variant="outline-light">EMAIL</Button> */}
                    </Card.Body>
            </Card>
        </Container>
    )
}

export default Contact
