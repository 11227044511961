import React from 'react'
import { Container } from 'react-bootstrap'
import studio from '../../assets/StudioMain.png';
//import jeff from '../../assets/JeffGrammyAcad.png';
import control from '../../assets/controlRM.png'
import tracking from '../../assets/session.png'
import gear from '../../assets/SHURE SM7MIC.JPG'

export default function About() {
    return (
        <Container className='about-container' fluid>
            <div className="about row featurette py-3" id="about">
                {/* <img id='about-header' fluid src={mix} alt='mixing room'/> */}
                <div className="col-md-7 mx-auto fluid">
                    <h2 className="featurette-heading">About three sixty sessions </h2>
                    <p className="lead"> From full-length albums to movie soundtracks and voice over work, three sixty sessions can create the audio you need for your project or business. Record instruments and vocals with world-class microphones, equipment and preamps in a relaxed, low-stress environment. </p>
                    
                </div>
                <div className="col-md-5 order-md-2">
                    <img id="studio" className='featurette-image img-fluid mx-auto' src={studio} width="500" height="375" aria-label="studio" preserveAspectRatio="xMidYMid slice" focusable="false"/>
                </div>
            </div>
            <hr className="featurette-divider bg-transparent" />
            <div className="about row featurette py-4" id="about-music">
                <div className="col-md-7 order-md-2">
                    <h2 className="featurette-heading2">Music Tracking | Multi-Track Mixing | Soundtrack Scoring</h2>
                    <p className="lead text-white">Our studio is set up to provide the comfort of a home studio with the sound quality of a major recording space. We have the space to do full-band recordings and equipment that gets great pro-level drum and instrument sounds without the price tag of a major recording facility. You can also take advantage of world class studio musicians to help bring your music to life for your next digital album or single release projext. </p>
                </div>
                <div className="col-md-5 order-md-1">
                    <img id="control" src={control} className='featurette-image img-fluid mx-auto' width="500" height="500" aria-label="control room photo" preserveAspectRatio="xMidYMid slice" focusable="false"/><title>control room</title>
                </div>
            </div>

            {/* <hr className="featurette-divider"/> */}

            <div className="about row featurette py-3" id="about">
                {/* <img id='about-header' fluid src={mix} alt='mixing room'/> */}
                <div className="col-md-7 mx-auto fluid">
                    {/* <h2 className="featurette-heading"></h2> */}
                    <p className="lead"> Our large tracking room offers the perfect accoustics to produce high quality drum sounds or even track a full band for a "live" feel. </p>

                </div>
                <div className="col-md-5 order-md-2">
                    <img id="studio" className='featurette-image img-fluid mx-auto' src={tracking} width="400"  aria-label="studio" preserveAspectRatio="xMidYMid slice" focusable="false"/>
                </div>
            </div>            

            <div className="about row featurette py-3" id="about-music">
                <div className="col-md-5 order-md-1">
                    {/* <h2 className="featurette-heading2">Music Tracking | Multi-Track Mixing | Soundtrack Scoring</h2> */}
                    <img id="control" src={gear} className='featurette-image img-fluid mx-auto' aria-label="recording" preserveAspectRatio="xMidYMid slice" focusable="false"/><title>control room</title>
                </div>
                <div className="col-md-7 order-md-1">
                    <p className="lead text-white">Choose from many industry standard, award-winning mics and boutique pre-amps. We have a wide selection of drums, acoustic and electric guitars, keyboards and guitar amps and a 1969 Leslie cabinet. </p>
                </div>
            </div>

            {/* Previous About Jeff */}
            {/* <div className="about row featurette py-3" id="about">
                <div className="col-md-7 fluid">
                    <h2 className="featurette-heading">About Jeff Mills </h2>
                    <h5 className="text-white ">Based on his quality of work, Jeff Mills was inducted into the <strong>Recording Academy of Engineers</strong> class of 2021. Soundtrack music used for the award-winning documentary <i>Jimmy Carter: Rock and Roll President</i> featured on CNN was recorded, mixed, and mastered by Jeff at threeSixty Sessions Studios. </h5>
                    <Button href="https://www.jeffmills.net" target="_blank" rel="noreferrer" variant="light" size="lg">
                    Learn More
                    </Button>
                </div>
                <div className="col-md-5 order-md-2">
                    <img id="about-jeff" className='featurette-image img-fluid mx-auto' src={jeff} width="500" height="375" aria-label="Jeff Mills photography by Drew Stawin" preserveAspectRatio="xMidYMid slice" focusable="false"/>

                </div>
            </div> */}
        </Container>
    )
}
