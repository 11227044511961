import React from 'react';
import { useState} from 'react';
import logo from '../../assets/360v5.png'
import Waveform from '../Sections/waveform'
import PlayList from "../Sections/Playlist";
import { Container, Row, Card } from 'react-bootstrap';

const tracks= [
    {
        id: 1,
        title:'Boys Will Be Boys Main Title',
        url: '/audio/BWBBMainTitle.mp3',
        artist: 'JEFF MILLS'
    },
    {
        id: 2,
        title:'Trailer Tag',
        url: '/audio/TrailerTag.mp3',
        artist: 'JEFF MILLS'
    }
]

function AudioMovie() {
    const [selectedTrack, setSelectedTrack] = useState(tracks[0]);

    return (
        <>
            <Container id="audio">
            <div id='audiorock'>
                <Row id="genre-card">
                    <Card id="card-player" style={{ width: '25rem' }}>
                        <Card.Img id="player-img" variant="top" src={logo} />
                        <Card.Body>
                            <div id='waveform'>
                                <Waveform  
                                        url={selectedTrack.url}
                                    />
                                    <div id='playlist'>
                                        <h6 id='playlist-title'>PLAYLIST:</h6>
                                        <PlayList
                                            tracks={tracks}
                                            selectedTrack={selectedTrack}
                                            setSelectedTrack={setSelectedTrack}
                                        />
                                    </div>   
                            </div>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
            </Container>
        </>
    )
}

export default AudioMovie
