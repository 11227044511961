import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';

export default function GearList() {
    return (
        <Container id="equip-page">
                <h2 className='gear-header text-center'>Equipment List</h2>
                <ListGroup className='bg-black text-white' id="equip-group">
                    <h4 className='gear-group'>Studio Microphones</h4>
                    <ListGroup.Item className='gear-title fw-bold'>NUEMANN:</ListGroup.Item>
                    <ListGroup.Item>KM184</ListGroup.Item>
                    <ListGroup.Item>U87</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>TELEFUNKEN:</ListGroup.Item>
                    <ListGroup.Item>M80 Snare</ListGroup.Item>
                    <ListGroup.Item>M81 x3 Toms/instruments</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>SE ELECTRONICS:</ListGroup.Item>
                    <ListGroup.Item>SE5600 Tube Mics x2</ListGroup.Item>
                    <ListGroup.Item>SE440a x2</ListGroup.Item>
                    <ListGroup.Item>SE5</ListGroup.Item>
                    <ListGroup.Item>SE2200</ListGroup.Item>
                    <ListGroup.Item>SE1a Stereo Pair</ListGroup.Item>
                    <ListGroup.Item>SE X1D x2 Kick/instrument Mic</ListGroup.Item>
                    <ListGroup.Item>SE X1R Ribbon x2</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>SHURE:</ListGroup.Item>
                    <ListGroup.Item>SM7</ListGroup.Item>
                    <ListGroup.Item>SM81</ListGroup.Item>
                    <ListGroup.Item>SM57 x2</ListGroup.Item>
                    <ListGroup.Item>SM58</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>GROOVE TUBE:</ListGroup.Item>
                    <ListGroup.Item>GT30</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>AKG:</ListGroup.Item>
                    <ListGroup.Item>D112 Kick Mic</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>AUDIX:</ListGroup.Item>
                    <ListGroup.Item>D6 Kick Mic</ListGroup.Item>
                    <ListGroup.Item>I5 Snare Instrumental Mic</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>STAGG:</ListGroup.Item>
                    <ListGroup.Item>SRM75S Stereo Ribbon Mic</ListGroup.Item>
                    <ListGroup.Item>SRM70 Ribbon x2</ListGroup.Item>
                    <ListGroup.Item>SDM70 - 57 Clones - x2</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>AUDIO TECHNICA:</ListGroup.Item>
                    <ListGroup.Item>KITPACK drum/instrumental mics x8</ListGroup.Item>
                    <ListGroup.Item>AT2020 x2</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>12 GAUGE MICROPHONES:</ListGroup.Item>
                    <ListGroup.Item>Red Omni x2</ListGroup.Item>
                </ListGroup>
        
                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Pre-Amps</h4>
                    <div className='preamp-info'>
                        Microphones shine the best when plugged into great preamps. Top shelf pre-amps add pleasing sound characters from each sound source and can enhance the performance of each microphone. In a digital age this is a very important aspect in getting warm rich tones from intruments as well as capturing the finest nuances of each unique vocalist. In addition to having a great sound tracking room, having the right preamps for recording acoustic drums is especially crucial to achieve a dynamic and punchy sound. The <em id="orange"> Presonus ADL 600 tube preamps </em>are considered by many to be some of the best tube preamps for recording any source but most notably, overhead/room drum mics, vocalis, acoustic guitars and bass. The <em id="orange">Presonus RC500's channel strips </em>offer a punchy fat sound which is especially desired on all close mic'ed drums and bass guitar. The <em id="orange">Black Lion B12A's </em>are absolutely stunning on acoustic and electric guitars bringing out the desired mid range regardless of genre. Finally, the <em id="orange">Golden Age Project </em>delivers that timeless sound of the Neve1073 style Preamps.
                    </div>
                    <ListGroup.Item className='gear-title fw-bold'>PRESONUS:</ListGroup.Item>
                    <ListGroup.Item>RC500 channel strips with compressors & EQ x4</ListGroup.Item>
                    <ListGroup.Item>ADL 600 Tube x4 channels - handmade by Anthony DiMaria</ListGroup.Item>
                    <ListGroup.Item>Studio Channel with EQ & compressor</ListGroup.Item>
                    <ListGroup.Item>Studio Live 24.4.2 console for additional mic inputs - up to 24 simultaneous channels</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>BLACK LION AUDIO:</ListGroup.Item>
                    <ListGroup.Item>B12A x2 - API 312 style</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>GOLDEN AGE PROJECT:</ListGroup.Item>
                    <ListGroup.Item>PRE-73 x4 - neve 1073 style</ListGroup.Item>
                </ListGroup>

                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Guitars</h4>
                    <ListGroup.Item className='gear-title fw-bold'>SEAGULL:</ListGroup.Item>
                    <ListGroup.Item>Entourage RUSTIC 6-string</ListGroup.Item>
                    <ListGroup.Item>12 string Acoustic</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>FENDER:</ListGroup.Item>
                    <ListGroup.Item>Telecaster</ListGroup.Item>
                </ListGroup>

                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Bass</h4>
                    <ListGroup.Item className='gear-title fw-bold'>YAMAHA:</ListGroup.Item>
                    <ListGroup.Item>4-string</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>IBANEZ:</ListGroup.Item>
                    <ListGroup.Item>4-string</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>MUSICMAN:</ListGroup.Item>
                    <ListGroup.Item>5-string</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>ELECTRA:</ListGroup.Item>
                    <ListGroup.Item>circa 1980's 4-string</ListGroup.Item>
                </ListGroup>

                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Instrument Amplifiers</h4>
                    <ListGroup.Item className='gear-title fw-bold'>EGNATER:</ListGroup.Item>
                    <ListGroup.Item>Tweaker Tube Head</ListGroup.Item>
                    <ListGroup.Item>1x12 inch Rebel Cabinet with Celestion Speaker</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>IBANEZ:</ListGroup.Item>
                    <ListGroup.Item>Tube Screamer Tube Head</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>LESLIE CABINET:</ListGroup.Item>
                    <ListGroup.Item>1969 Leslie 145 Organ/Guitar Amp</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>AMPEG:</ListGroup.Item>
                    <ListGroup.Item>1x10 Bass Combo</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>CELESTION:</ListGroup.Item>
                    <ListGroup.Item>1x12 inch Green Back Cabinet</ListGroup.Item>
                </ListGroup>

                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Drum Sets</h4>
                    <ListGroup.Item className='gear-title fw-bold'>YAMAHA BIRCH CUSTOM:</ListGroup.Item>
                    <ListGroup.Item>22, 20 kick 8, 10, 12, 13 4, 16 toms</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>YAMAHA BEECH CUSTOM:</ListGroup.Item>
                    <ListGroup.Item>22 kick, 10, 12, 14 toms</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>YAMAHA OAK CUSTOM:</ListGroup.Item>
                    <ListGroup.Item>22 kick, 10, 12, 15 toms</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>DIXON ARTISAN MAPLE:</ListGroup.Item>
                    <ListGroup.Item>10, 18 kick, 10, 12, 13, 15 toms</ListGroup.Item>
                </ListGroup>

                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Snare Drums</h4>
                    <ListGroup.Item className='gear-title fw-bold'>YAMAHA:</ListGroup.Item>
                    <ListGroup.Item>Manu Katche - Nickel Brass 14x5.5</ListGroup.Item>
                    <ListGroup.Item>Dave Weckl - Aluminum 14.6.5</ListGroup.Item>
                    <ListGroup.Item>Steve Gadd - Birch 13x5</ListGroup.Item>
                    <ListGroup.Item>Sonny Emory - Beech 14.5.5</ListGroup.Item>
                    <ListGroup.Item>Oak Custom 14.5.5</ListGroup.Item>
                    <ListGroup.Item>Birch Custom 14x4, 10x5</ListGroup.Item>
                    <ListGroup.Item>Brass 13x6</ListGroup.Item>
                    <ListGroup.Item>Steel 14x5.5, 12x5</ListGroup.Item>
                    <ListGroup.Item>Bamboo 14x5.5</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>DIXON:</ListGroup.Item>
                    <ListGroup.Item>Equator Oak Steel 14.5.5</ListGroup.Item>
                    <ListGroup.Item>Rosewood 13x5</ListGroup.Item>
                    <ListGroup.Item>Steel 14x6.5</ListGroup.Item>
                    <ListGroup.Item>Aluminum 14x5.5</ListGroup.Item>
                    <ListGroup.Item>Maple 14x8, 13x6.5</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>LUDWIG:</ListGroup.Item>
                    <ListGroup.Item>Acrolite Aluminum 14x5</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>PREMIER:</ListGroup.Item>
                    <ListGroup.Item>Birch 14x5.5</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>TAYE:</ListGroup.Item>
                    <ListGroup.Item>Brass 14x6.5</ListGroup.Item>
                </ListGroup>
                
                <ListGroup className='bg-black text-white' id="equip-group">
                <h4 className='gear-group'>Cymbals</h4>
                    <ListGroup.Item className='gear-title fw-bold'>ZILDJIAN:</ListGroup.Item>
                    <ListGroup.Item>A Customs, K Customs A Series</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>SABIAN:</ListGroup.Item>
                    <ListGroup.Item>HH and AA</ListGroup.Item>
                    <ListGroup.Item className='gear-title fw-bold'>STAGG:</ListGroup.Item>
                    <ListGroup.Item>Genghis Hand Hammered. SH and DH Series</ListGroup.Item>
                </ListGroup>
                
        </Container>
    )
}
