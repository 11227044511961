import React from "react";

const PlayList = ({ tracks, selectedTrack, setSelectedTrack }) => {
  return (
    <div className="playlist">
      {tracks.map(track => (
        <div
          key={track.title}
          className={
            track.id === selectedTrack.id
              ? "playlist-item selected"
              : "playlist-item"
          }
          onClick={() => setSelectedTrack(track)}
        ><button id="trackTitle">
          <ul>
            <li>{track.id}. {track.title} by {track.artist} </li>
          </ul>
          
        </button>
          
        </div>
      ))}
    </div>
  );
};

export default PlayList;