import React from 'react';
import {Container, Row, Button } from 'react-bootstrap'
import jeff from '../../assets/JeffGrammyAcad.png'

function AboutJeff() {
    return (
        <div>
            <Container fluid>
                <Row className="about featurette py-3" id="about-jeff">
                    <div className="col-md-5 order-md-2">
                        <img id="about-jeff" className='featurette-image img-fluid mx-auto' src={jeff} width="500" height="375" aria-label="Jeff Mills photography by Drew Stawin" preserveAspectRatio="xMidYMid slice" focusable="false"/>
                    </div>
                    <div className="col-md-7 fluid">
                        <h2 className="featurette-heading">About Jeff Mills </h2>
                        <h4 className='lead'> Studio Owner | Producer </h4>
                        <h5 className="lead text-white" id="jeff-text">Based on his quality of work, Jeff Mills was inducted into the Recording Academy of Engineers class of 2021. Soundtrack music used for the award-winning documentary <i>Jimmy Carter: Rock and Roll President</i> featured on CNN was recorded, mixed, and mastered by Jeff at three sixty sessions Studios. </h5>
                        {/* Link to Jeff musician site */}
                        {/* <Button href="https://www.jeffmills.net" target="_blank" rel="noreferrer" variant="light" size="lg">
                        Learn More
                        </Button> */}
                        <Button  href="http://voyageatl.com/interview/meet-jeff-mills-three-sixty-sessions-studio-duluthjohns-creek/" target="_blank" rel="noreferrer" id="interview-link" variant="outline-light">Read interview with Jeff at <em>VoyageATL</em> magazine</Button>
                    </div>

                </Row>
            </Container>
        </div>
    )
}

export default AboutJeff;
