import React, { useState } from 'react'
//import { Link } from 'react-router-dom'
import logo from '../assets/360v5.png'
import {Navbar, Nav, Container} from 'react-bootstrap'
import {NavDropdown} from 'react-bootstrap'
//import Dropdown from './Dropdown'

function Navigation() {
    const[click, setClick] = useState(false)
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if(window.innerWidth < 960) {
            setDropdown(false)
        } else {
            setDropdown(true)
        }
    };

    const onMouseLeave = () => {
        if(window.innerWidth < 960) {
            setDropdown(false)
        } else {
            setDropdown(false)
        }
    };

    return (
        <>
            <Navbar collapseOnSelect expand='lg'className='navbar-transparent' variant='dark' sticky="top">
                <Container fluid>
                    <Navbar.Brand  className="brand">                 
                            <img
                                src={logo}
                                className="d-inline-block align-top"
                                id="navPic"
                                alt="logo"
                            />
                        three sixty sessions
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>

                        {/*Home page part of nav */}
                            <Nav.Link 
                            href='/' 
                            className='nav-links'
                            id='nav-link'
                            onClick={closeMobileMenu}
                            >
                                Home
                            </Nav.Link>

                        {/*About page of nav */}
                            {/* <NavDropdown className='nav-links' title="About" id="collapsible-nav-dropdown">
                                <Link id="nav-background" 
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    to='/About' 
                                    className='nav-links' 
                                    onClick={closeMobileMenu}
                                    > About360
                                </Link>
                                <Link id="nav-background" 
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    to='/AboutJeff' 
                                    className='nav-links' 
                                    onClick={closeMobileMenu}
                                    > AboutJeff
                                </Link> */}
                                {/* <Nav.Link
                                    href='/About' 
                                    className='nav-links'
                                    id='nav-link' 
                                    onClick={closeMobileMenu}
                                    >
                                        About
                                </Nav.Link> */}
                            {/* </NavDropdown> */}

                            <NavDropdown
                                id="nav-dropdown-light-example nav-link"
                                title="About"
                                menuVariant="dark"
                                >
                                    <NavDropdown.Item href="/About">three sixty</NavDropdown.Item>
                                    <NavDropdown.Item href="/AboutJeff">Jeff Mills</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="/GearList">Gear List</NavDropdown.Item> */}

                            </NavDropdown>
                                    
                            {/*studio services with DROPDOWN section of nav save for later in case add Video Page */}
                            {/* <NavDropdown className='nav-links' title="Studio Services" id="collapsible-nav-dropdown">
                                <Link id="nav-background" 
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    to='/Audio' 
                                    className='nav-links' 
                                    onClick={closeMobileMenu}
                                    > Audio
                                </Link>

                                {/* <Link 
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    to='/Video' 
                                    className='nav-links' 
                                    onClick={closeMobileMenu}
                                    > Video 
                                </Link> */}

                                {/* </Nav><Link 
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    to='/Booking' 
                                    className='nav-links' 
                                    onClick={closeMobileMenu}
                                    > Booking 
                                </Link>
                            </NavDropdown> */}

                                <Nav.Link
                                href='/Audio' 
                                className='nav-links'
                                id='nav-link'
                                onClick={closeMobileMenu}
                                >
                                    Samples
                                </Nav.Link>

                                {/* Leave in incase client decides to use later */}
                                {/* <Nav.Link
                                href='/Booking' 
                                className='nav-links'
                                id='nav-link'
                                onClick={closeMobileMenu}
                                >
                                    Booking
                                </Nav.Link> */}
                                <Nav.Link
                                href='/Studio' 
                                className='nav-links'
                                id='nav-link'
                                onClick={closeMobileMenu}
                                >
                                    Studio
                                </Nav.Link>

                                <Nav.Link
                                href='/Contact' 
                                className='nav-links'
                                id='nav-link'
                                onClick={closeMobileMenu}
                                >
                                    Contact
                                </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> 
        </>
    )
}

export default Navigation;
